import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
} from "@ionic/react";

import CSS from "csstype";

const Play: React.FC = () => {
  const iframeStyles: CSS.Properties = {
    border: 0,
    height: "100%",
    width: "100%",
  };

  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <iframe
          style={iframeStyles}
          src="https://www.huronpokerleague.com/poker-login/"
          scrolling="yes"
        ></iframe>
      </IonContent>
    </IonPage>
  );
};

export default Play;
