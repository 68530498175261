import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { ellipse, basket, listOutline, logoVenmo, dice, personCircleSharp } from 'ionicons/icons';


import Play from "./pages/Play";
import MyAccount from "./pages/MyAccount";



const MainTabs: React.FC = () => {
    return (
      <IonTabs>
        <IonRouterOutlet>
          <Redirect exact path="/" to="/play" />
          <Route exact={true} path="/:tab(play)">
            <Play />
          </Route>
          <Route exact={true} path="/:tab(myaccount)">
            <MyAccount />
          </Route>
        </IonRouterOutlet>
        <IonTabBar className="tabbar" slot="bottom">
          <IonTabButton tab="play" href="/play">
            <IonIcon icon={dice} />
            <IonLabel>Play</IonLabel>
          </IonTabButton>
          <IonTabButton tab="myaccount" href="/myaccount">
            <IonIcon icon={personCircleSharp} />
            <IonLabel>Account</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    );
}

export default MainTabs;